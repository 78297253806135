import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-91f9d854"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-content"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "box"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "introduce"
};
const _hoisted_7 = {
  class: "introduce bottom-box"
};
const _hoisted_8 = ["href"];
import i18n from '@/language/index';
import { ref } from 'vue';
export default {
  __name: 'AboutUs',
  setup(__props) {
    // import Header from '@/components/Header'
    // import PageFooter from '@/components/PageFooter'
    const zhLargeUrl = require('../assets/images/mainPageIntro.jpg');
    const ehLargeUrl = require('../assets/enImages/mainPageIntro.jpg');
    const zhTextUrl = require('../assets/images/txt.png');
    const ehTextUrl = require('../assets/enImages/txt.png');
    const zhHref = ref('http://design.hnu.edu.cn/info/1023/5787.htm');
    const enHref = ref('http://design.hnu.edu.cn/info/1114/6275.htm');
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("img", {
        class: "aboutUs",
        src: _unref(i18n).global.locale.value === 'en' ? _unref(ehLargeUrl) : _unref(zhLargeUrl),
        alt: ""
      }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
        src: _unref(i18n).global.locale.value === 'en' ? _unref(ehTextUrl) : _unref(zhTextUrl),
        alt: ""
      }, null, 8, _hoisted_4), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('aboutUs.title')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('aboutUs.laboratory')), 1), _createElementVNode("div", _hoisted_7, [_createTextVNode(_toDisplayString(_ctx.$t('aboutUs.research')), 1), _createElementVNode("a", {
        href: _unref(i18n).global.locale.value === 'zh' ? zhHref.value : enHref.value,
        target: "_blank"
      }, _toDisplayString(_ctx.$t('aboutUs.homepage')), 9, _hoisted_8)])])]);
    };
  }
};